<template>
    <div class="loader_area no_sidebar" v-if="contactCurrentProgressLoader || contactCurrentProgressLoaderV2 || reportLoader"><quote-loader/></div>
    <div v-else class="stat_container" :style="`--var-element-bg-color: ${progressTracking.public_settings ? progressTracking.public_settings.element_color : '#f5f5f5'}; --var-element-text-color: ${progressTracking.public_settings ? progressTracking.public_settings.element_text_color : '#121525'}; --var-element-light-color: ${progressTracking.public_settings ? progressTracking.public_settings.element_color : '#f5f5f5'}20;`">
        <div class="section_header mb-4">
            <h2>Client Tracking <span class="tag">{{ progressTracking ? progressTracking.name : '' }}</span></h2>
            <div class="optionDrops contacts-tabs" @click="durationFilter = !durationFilter" v-click-outside="closeDurationDropdown">
                {{ selectedFilter }} <i class="fas fa-angle-down"></i>
                <div class="dropdown_wpr" :class="durationFilter ? 'active' : ''">
                    <ul>
                        <li v-for="(filter, f) of filters" :key="f" @click="params.filter = filter.type; selectedFilter = filter.title; filterDropdown = false;">{{ filter.title }}</li>
                    </ul>
                </div>
            </div>
            <div class="optionDrops" @click="scenarioFilter = !scenarioFilter;" v-click-outside="closeScenarioDropdown">
                Filter
                <div class="dropdown_wpr progress_filter_bar" :class="scenarioFilter ? 'active' : ''">
                    <div class="category_wpr">
                        <div class="category_grp active" v-for="(category, c) of currentProgress.categories" :key="c">
                            <h4>{{ category.title }}</h4>
                            <ul>
                                <li v-for="(prog, p) of category.progresses" :key="p" @click.stop="prog.enabled = !prog.enabled">
                                    <label :for="`filter-${p}`" class="checkbox">
                                        <span :class="{'active' : prog.enabled}"><i class="fas fa-check"></i></span>
                                        <h5>{{ prog.title }}</h5>
                                    </label>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="report_card">
            <div class="header">
                <div class="user_info">
                    <img :src="contact.photo" alt="">
                    <div>
                        <h4>{{ contact.name }}</h4>
                        <h5>Started Tracking &nbsp;<span>{{ moment(currentProgress.date_started).format('ll') }}</span></h5>
                    </div>
                </div>
                <ul class="total_progress">
                    <li>
                        <h3>{{ completed.length }}<span>Days</span></h3>
                        <label class="completed">Completed</label>
                    </li>
                    <li>
                        <h3>{{ missed.length }}<span>Days</span></h3>
                        <label class="missed">Missed</label>
                    </li>
                </ul>
            </div>
            <div class="overview">
                <template v-for="(category, c) of currentProgress.categories" :key="c">
                    <template v-if="category.progresses && category.progresses.length">
                        <div class="category_label" v-if="currentProgress.categories.length > 1">
                            <span>{{ category.title }}</span>
                        </div>
                        <ul>
                            <template v-for="(prog, p) of category.progresses" :key="p">
                                <li v-if="prog.enabled">
                                    <img v-if="prog.icon" :src="prog.icon" :alt="prog.title">
                                    <img v-else-if="prog.default_icon" :src="require(`@/assets/images/${prog.default_icon}`)" :alt="prog.title">
                                    <h4 v-click-outside="closeNotes">
                                        <div class="note-wpr">
                                            {{ prog.title }} <span v-if="prog.notes.length" class="pointer" @click="toggleNote(`notes-${prog.id}`)"><i class="fa fa-clipboard-list"></i></span>
                                            <div class="notes" :id="`notes-${prog.id}`" v-if="prog.notes.length">
                                                <label class="input_label">Notes:</label>
                                                <ul class="mb-2">
                                                    <li v-for="(note, n) of prog.notes" :key="n">{{ note }}</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <span v-if="prog.is_pull_up || prog.is_push_up">
                                            Average
                                            <a class="capitalize">
                                                <template v-for="(item, i) of prog.settings.measurements" :key="i">
                                                    <template v-if="item.enabled">
                                                        {{ prog.average_score[item.title] }} {{ item.title }} &nbsp;
                                                    </template>
                                                </template>
                                            </a>
                                        </span>
                                        <span v-else-if="prog.average_score">Average <a>{{ prog.average_score }} {{ prog.unit_label }}</a></span>
                                        <span v-else-if="prog.is_circumference || prog.is_macros || prog.is_vitamins || prog.is_minerals || prog.is_supplements || prog.is_workout || prog.is_zones || prog.is_blood_pressure || prog.is_sleep || prog.is_stool || prog.is_pain"></span>
                                        <span v-else>Not available</span>
                                    </h4>
                                </li>
                            </template>
                        </ul>
                    </template>
                </template>
            </div>
        </div>
        <div class="pic_wrapper" v-if="progressTracking.data_settings && progressTracking.data_settings.has_progress_pics && currentProgress.pictures && currentProgress.pictures.cluster && Object.values(currentProgress.pictures.cluster).length">
            <h3 class="title"><img :src="require(`@/assets/images/selfie.svg`)">Progress Pics</h3>
            <swiper :breakpoints="{'499': { slidesPerView: 1 }, '599': { slidesPerView: 2 }}" :spaceBetween="30" :navigation='true' class="pic_slider">
                <template v-for="(clusters, c ) of  currentProgress.pictures.cluster" :key="c">
                    <swiper-slide v-for="(picture, p) of clusters" :key="p">
                        <div class="picture_section">
                            <div class="date"><span class="caption">{{ picture.caption }}</span> <span>{{ moment(picture.scheduled_date).format('ll') }}</span></div>
                            <ul class="section_wpr">
                                <li v-if="picture.front_photo && progressTracking.data_settings.photo_view.front">
                                    <div class="section_title d-flex-sb">
                                        <h4>Front</h4>
                                    </div>
                                    <div class="img_viewer">
                                        <img @click="previewImage([{ title: 'Front', src: picture.front_photo }, { title: 'Side', src: picture.side_photo }, { title: 'Back', src: picture.back_photo }])" :src="picture.front_photo">
                                        <button type="button" class="lightbox_btn" @click="previewImage([{ title: 'Front', src: picture.front_photo }, { title: 'Side', src: picture.side_photo }, { title: 'Back', src: picture.back_photo }])"><i class="fas fa-search-plus"></i></button>
                                        <a type="button" class="download_btn" :href="picture.front_photo" download target="_blank"><i class="fas fa-download"></i></a>
                                    </div>
                                </li>
                                <li v-if="picture.side_photo && progressTracking.data_settings.photo_view.side">
                                    <div class="section_title d-flex-sb">
                                        <h4>Side</h4>
                                    </div>
                                    <div class="img_viewer">
                                        <img @click="previewImage([{ title: 'Side', src: picture.side_photo }, { title: 'Back', src: picture.back_photo }, { title: 'Front', src: picture.front_photo }])" :src="picture.side_photo">
                                        <button type="button" class="lightbox_btn" @click="previewImage([{ title: 'Side', src: picture.side_photo }, { title: 'Back', src: picture.back_photo }, { title: 'Front', src: picture.front_photo }])"><i class="fas fa-search-plus"></i></button>
                                        <a type="button" class="download_btn" :href="picture.side_photo" download target="_blank"><i class="fas fa-download"></i></a>
                                    </div>
                                </li>
                                <li v-if="picture.back_photo && progressTracking.data_settings.photo_view.back">
                                    <div class="section_title d-flex-sb">
                                        <h4>Back</h4>
                                    </div>
                                    <div class="img_viewer">
                                        <img @click="previewImage([{ title: 'Back', src: picture.back_photo }, { title: 'Front', src: picture.front_photo }, { title: 'Side', src: picture.side_photo }])" :src="picture.back_photo">
                                        <button type="button" class="lightbox_btn" @click="previewImage([{ title: 'Back', src: picture.back_photo }, { title: 'Front', src: picture.front_photo }, { title: 'Side', src: picture.side_photo }])"><i class="fas fa-search-plus"></i></button>
                                        <a type="button" class="download_btn" :href="picture.back_photo" download target="_blank"><i class="fas fa-download"></i></a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </swiper-slide>
                </template>
            </swiper>
        </div>
        <template v-for="(category, c) of currentProgress.categories" :key="c">
            <template v-for="(prog, p) of category.progresses" :key="p">
                <progress-chart v-if="prog.enabled" :category-progress="prog" :tracking-goal="trackingGoals[prog.id]" :tracking="progressTracking" :settings="settings" />
            </template>
        </template>

        <vue-easy-lightbox :visible="previewImagePanel" :index="selectdImageIndex" :imgs="availableImages" @hide="previewImagePanel = false"></vue-easy-lightbox>

        <div class="content_card_wpr show" id="progress_history">
            <div class="title">
                <h4>Completed Trackings <label>{{ completed.length }}</label></h4>
                <div class="rate">
                    <div class="score_circle" style="background: #d9d9d9; width:40px; height:40px; padding: 4px;">
                        <div class="inner_circle">
                            <div class="score">{{ completed.length ? Math.round((completed.length * 100) / (completed.length + missed.length)) : 0 }}<span>%</span></div>
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40">
                            <circle cx="20" cy="20" r="18" stroke-linecap="round" fill="none" stroke-width="4px" :stroke="chartColor" :stroke-dasharray="115" :stroke-dashoffset="115 - (completed.length ? (Math.round((completed.length * 100) / (completed.length + missed.length)) * 1.15) : 0)" />
                        </svg>
                    </div>
                    <h6>Completion rate</h6>
                </div>
                <button type="button" class="toggle_btn" @click="toggleFunc"><i class="fas fa-chevron-down"></i></button>
            </div>
            <div class="section_content">
                <ul class="list workout_card workout_list">
                    <template v-for="(complete, c) of completed" :key="c">
                        <li>
                            <div class="card_wrapper">
                                <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="33" cy="33" r="32" stroke="#30C13E" stroke-width="2"/>
                                    <path d="M27.6317 47.0433L16.2034 34.5473C15.5168 33.7966 15.5168 32.5793 16.2034 31.8285L18.6898 29.1097C19.3764 28.3589 20.4897 28.3589 21.1763 29.1097L28.8749 37.5276L45.3646 19.4974C46.0512 18.7466 47.1645 18.7466 47.8511 19.4974L50.3375 22.2162C51.0241 22.9669 51.0241 24.1841 50.3375 24.935L30.1182 47.0434C29.4315 47.7942 28.3183 47.7942 27.6317 47.0433V47.0433Z" fill="#30C13E"/>
                                </svg>
                                <div class="info_wpr">
                                    <h4 @click="showQuestions($event)"><span class="date">{{ moment(complete.scheduled_date).format('ll') }}</span><i class="fas fa-chevron-down"></i></h4>
                                    <div class="details has-scroll">
                                        <div class="blocks">
                                            <ol class="todo_list">
                                                <li v-for="(response, r) of complete.responses" :key="r">
                                                    <label>{{  ++r  }}</label>
                                                    <div class="task_ans w-100">
                                                        {{  response.question }}
                                                        <ul class="field_list" v-if="response.info.is_pull_up || response.info.is_push_up || response.info.is_circumference">
                                                            <template v-for="(measurement, m) of response.info.settings.measurements" :key="m">
                                                                <li v-if="measurement.enabled">
                                                                    <label class="capitalize">{{ measurement.title }}</label>
                                                                    <div class="submitted_data">
                                                                        <template v-if="measurement.title == 'rest'">
                                                                            {{ response.answer[measurement.title].rest ? response.answer[measurement.title].rest.rest_min + ':' + response.answer[measurement.title].rest.rest_sec : response.answer[measurement.title].rest_min + ':' + response.answer[measurement.title].rest_sec }}
                                                                        </template>
                                                                        <template v-else>
                                                                            {{ response.answer[measurement.title] ? response.answer[measurement.title] : 0 }}
                                                                        </template>
                                                                        <span v-if="response.info.unit">{{ response.info.unit }}</span>
                                                                    </div>
                                                                </li>
                                                            </template>
                                                        </ul>
                                                        <ul class="field_list" v-else-if="response.info.is_pain">
                                                            <template v-for="(upper, u) of response.info.settings.upper_body" :key="u">
                                                                <li v-if="upper.enabled && response.answer.upper && response.answer.upper.includes(upper.title)">
                                                                    <label class="capitalize">{{ upper.title }}</label>
                                                                    <div class="submitted_data">
                                                                        {{ response.answer.pain_level[upper.title] ? response.answer.pain_level[upper.title] : 0 }}
                                                                    </div>
                                                                </li>
                                                            </template>
                                                            <template v-for="(lower, l) of response.info.settings.lower_body" :key="l">
                                                                <li v-if="lower.enabled && response.answer.lower && response.answer.lower.includes(lower.title)">
                                                                    <label class="capitalize">{{ lower.title }}</label>
                                                                    <div class="submitted_data">
                                                                        {{ response.answer.pain_level[lower.title] ? response.answer.pain_level[lower.title] : 0 }}
                                                                    </div>
                                                                </li>
                                                            </template>
                                                        </ul>
                                                        <div class="field_item"  v-else-if="response.info.is_stool">
                                                            <div class="submitted_data">
                                                                {{ response.answer ? response.answer.title : '' }}
                                                            </div>
                                                        </div>
                                                        <ul class="field_list" v-else-if="response.info.is_sleep">
                                                            <li>
                                                                <label class="capitalize">Duration</label>
                                                                <div class="submitted_data">
                                                                    {{ response.answer.duration ? response.answer.duration : 0 }}
                                                                    <span>hrs</span>
                                                                </div>
                                                            </li>
                                                            <li v-if="response.info.settings.has_sleep_quality">
                                                                <label class="capitalize">Quality</label>
                                                                <div class="submitted_data">
                                                                    {{ response.answer.sleep_quality ? response.answer.sleep_quality : 0 }}
                                                                </div>
                                                            </li>
                                                            <template v-for="(measurement, m) of response.info.settings.measurements" :key="m">
                                                                <li v-if="measurement.enabled">
                                                                    <label class="capitalize">{{ Helper.capitalizeFirstLetter(measurement.title.replaceAll('_', ' ').replaceAll('rem', 'REM')) }}</label>
                                                                    <div class="submitted_data">
                                                                        {{ response.answer[measurement.title] ? response.answer[measurement.title] : 0 }}
                                                                        <span>hrs</span>
                                                                    </div>
                                                                </li>
                                                            </template>
                                                        </ul>
                                                        <ul class="field_list" v-else-if="response.info.is_workout">
                                                            <template v-for="(measurement, m) of response.info.settings.measurements" :key="m">
                                                                <li v-if="measurement.enabled && response.answer.workouts && response.answer.workouts.includes(measurement.title)">
                                                                    <label class="capitalize">{{ Helper.capitalizeFirstLetter(measurement.title.replaceAll('_', ' ').replaceAll('rem', 'REM')) }}</label>
                                                                    <div class="submitted_data">
                                                                        {{ response.answer.duration[measurement.title] ? response.answer.duration[measurement.title] : 0 }}
                                                                        <span>min</span>
                                                                    </div>
                                                                </li>
                                                            </template>
                                                        </ul>
                                                        <ul class="field_list" v-else-if="response.info.is_zones || response.info.is_blood_pressure || response.info.is_macros">
                                                            <template v-for="(measurement, m) of response.info.settings.measurements" :key="m">
                                                                <li v-if="measurement.enabled">
                                                                    <label class="capitalize">{{ Helper.capitalizeFirstLetter(measurement.title.replaceAll('_', ' ').replaceAll('rem', 'REM')) }}</label>
                                                                    <div class="submitted_data">
                                                                        {{ response.answer[measurement.title] ? response.answer[measurement.title] : 0 }}
                                                                        <span>min</span>
                                                                    </div>
                                                                </li>
                                                            </template>
                                                        </ul>
                                                        <ul class="check_list" v-else-if="response.info.is_vitamins || response.info.is_minerals || response.info.is_supplements">
                                                            <template v-for="(measurement, m) of response.info.settings" :key="m">
                                                                <li v-if="measurement.enabled">
                                                                    <label :for="measurement.title.replaceAll(' ', '-').toLowerCase()" class="checkbox">
                                                                        <span><i class="fas fa-check" v-if="response.answer && response.answer.includes(measurement.title)"></i></span>
                                                                        <p>{{ measurement.title }}</p>
                                                                    </label>
                                                                </li>
                                                            </template>
                                                        </ul>
                                                        <div class="field_item"  v-else>
                                                            <div class="submitted_data">
                                                                {{ response.answer ? response.answer : 0 }}
                                                                <span v-if="response.info.unit">{{ response.info.unit }}</span>
                                                            </div>
                                                        </div>
                                                        <div v-if="response.info.has_notes && response.notes">
                                                            <span><b>Notes:</b> {{ response.notes }}</span>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ol>
                                            <div class="submit_details">
                                                <span class="success" v-if="complete.is_submitted && complete.is_edited">Edited After Submission</span>
                                                <span class="delay" v-else-if="moment.duration(moment(complete.updated_at).diff(complete.scheduled_at)).asHours() >= 23">Submitted Late</span>
                                                <span v-else>Submitted On Time</span>
                                                <label v-if="complete.submitted_at">Already submitted on {{ moment.utc(complete.submitted_at).tz(timezone).format('ll') }} at {{ moment.utc(complete.submitted_at).tz(timezone).format('LT') }} ({{ timezone }})</label>
                                                <label v-else>Already submitted on {{ moment.utc(complete.created_at).tz(timezone).format('ll') }} at {{ moment.utc(complete.created_at).tz(timezone).format('LT') }} ({{ timezone }})</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </template>
                </ul>

            </div>
        </div>
        <div class="content_card_wpr show" id="missed_progress_history">
            <div class="title">
                <h4>Missed Trackings <label>{{ missed.length }}</label></h4>
                <div class="rate">
                    <div class="score_circle" style="background: #d9d9d9; width:40px; height:40px; padding: 4px;">
                        <div class="inner_circle">
                            <div class="score">{{ missed.length ? Math.round((missed.length * 100) / (completed.length + missed.length)) : 0 }}<span>%</span></div>
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40">
                            <circle cx="20" cy="20" r="18" stroke-linecap="round" fill="none" stroke-width="4px" :stroke="chartColor" :stroke-dasharray="115" :stroke-dashoffset="115 - (missed.length ? (Math.round((missed.length * 100) / (completed.length + missed.length)) * 1.15) : 0)" />
                        </svg>
                    </div>
                    <h6>Completion rate</h6>
                </div>
                <button type="button" class="toggle_btn" @click="toggleFunc"><i class="fas fa-chevron-down"></i></button>
            </div>
            <div class="section_content">
                <ul class="list workout_card workout_list">
                    <template v-for="(miss, m) of missed" :key="m">
                        <li>
                            <div class="card_wrapper">
                                <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M20.1832 19.8168C18.8702 21.1298 18.8702 23.2585 20.1832 24.5714L28.6119 33.0001L20.1833 41.4287C18.8703 42.7416 18.8703 44.8703 20.1833 46.1833C21.4962 47.4962 23.6249 47.4962 24.9379 46.1833L33.3665 37.7547L41.795 46.1833C43.108 47.4962 45.2367 47.4962 46.5496 46.1833C47.8626 44.8703 47.8626 42.7416 46.5497 41.4287L38.1211 33.0001L46.5497 24.5714C47.8627 23.2585 47.8627 21.1298 46.5497 19.8168C45.2368 18.5039 43.1081 18.5039 41.7951 19.8168L33.3665 28.2455L24.9378 19.8168C23.6249 18.5039 21.4961 18.5039 20.1832 19.8168Z" fill="#EC4242"/>
                                    <circle cx="33" cy="33" r="32" stroke="#EC4242" stroke-width="2"/>
                                </svg>
                                <div class="info_wpr">
                                    <h4 @click="showQuestions($event)"><span class="date">{{ moment(miss.scheduled_date).format('ll') }}</span><i class="fas fa-chevron-down"></i></h4>
                                    <div class="details has-scroll">
                                        <div class="blocks">
                                            <ol class="todo_list">
                                                <li v-for="(response, r) of miss.responses" :key="r">
                                                    <label>{{  ++r  }}</label>
                                                    <div class="task_ans">
                                                        {{  response.question }}
                                                        <ul class="field_list" v-if="response.info.is_pull_up || response.info.is_push_up || response.info.is_circumference">
                                                            <template v-for="(measurement, m) of response.info.settings.measurements" :key="m">
                                                                <li v-if="measurement.enabled" :class="{ 'rest-field': measurement.title == 'rest' }">
                                                                    <label class="capitalize">{{ measurement.title }}</label>
                                                                    <template v-if="measurement.title == 'rest'">
                                                                        <div class="rest">
                                                                            <div class="field_area has_suffix">
                                                                                <input type="number" placeholder="ex: 37">
                                                                                <span class="suffix">min</span>
                                                                            </div>
                                                                            <div class="field_area has_suffix">
                                                                                <input type="number" placeholder="ex: 37">
                                                                                <span class="suffix">sec</span>
                                                                            </div>
                                                                        </div>
                                                                    </template>
                                                                    <template v-else>
                                                                        <div class="field_area has_suffix">
                                                                            <input type="number" placeholder="ex: 37">
                                                                            <span class="suffix" v-if="response.info.unit">{{ response.info.unit }}</span>
                                                                        </div>
                                                                    </template>
                                                                </li>
                                                            </template>
                                                        </ul>
                                                        <ul class="field_list" v-else-if="response.info.is_pain">
                                                            <template v-for="(upper, u) of response.info.settings.upper_body" :key="u">
                                                                <li v-if="upper.enabled && response.answer.upper && response.answer.upper.includes(upper.title)">
                                                                    <label class="capitalize">{{ upper.title }}</label>
                                                                    <div class="field_area has_suffix">
                                                                        <input type="number" placeholder="ex: 37">
                                                                    </div>
                                                                </li>
                                                            </template>
                                                            <template v-for="(lower, l) of response.info.settings.lower_body" :key="l">
                                                                <li v-if="lower.enabled && response.answer.lower && response.answer.lower.includes(lower.title)">
                                                                    <label class="capitalize">{{ lower.title }}</label>
                                                                    <div class="field_area has_suffix">
                                                                        <input type="number" placeholder="ex: 37">
                                                                    </div>
                                                                </li>
                                                            </template>
                                                        </ul>
                                                        <ul class="field_list" v-else-if="response.info.is_sleep">
                                                            <li>
                                                                <label class="capitalize">Duration</label>
                                                                <div class="field_area has_suffix">
                                                                    <input type="number" placeholder="ex: 37">
                                                                    <span class="suffix">hrs</span>
                                                                </div>
                                                            </li>
                                                            <li v-if="response.info.settings.has_sleep_quality">
                                                                <label class="capitalize">Quality</label>
                                                                <div class="submitted_data">
                                                                    {{ response.answer.sleep_quality ? response.answer.sleep_quality : 0 }}
                                                                </div>
                                                            </li>
                                                            <template v-for="(measurement, m) of response.info.settings.measurements" :key="m">
                                                                <li v-if="measurement.enabled">
                                                                    <label class="capitalize">{{ Helper.capitalizeFirstLetter(measurement.title.replaceAll('_', ' ').replaceAll('rem', 'REM')) }}</label>
                                                                    <div class="field_area has_suffix">
                                                                        <input type="number" placeholder="ex: 37">
                                                                        <span class="suffix">hrs</span>
                                                                    </div>
                                                                </li>
                                                            </template>
                                                        </ul>
                                                        <ul class="field_list" v-else-if="response.info.is_workout">
                                                            <template v-for="(measurement, m) of response.info.settings.measurements" :key="m">
                                                                <li v-if="measurement.enabled && response.answer.workouts && response.answer.workouts.includes(measurement.title)">
                                                                    <label class="capitalize">{{ Helper.capitalizeFirstLetter(measurement.title.replaceAll('_', ' ').replaceAll('rem', 'REM')) }}</label>
                                                                    <div class="field_area has_suffix">
                                                                        <input type="number" placeholder="ex: 37">
                                                                        <span class="suffix">min</span>
                                                                    </div>
                                                                </li>
                                                            </template>
                                                        </ul>
                                                        <ul class="field_list" v-else-if="response.info.is_zones || response.info.is_blood_pressure || response.info.is_macros">
                                                            <template v-for="(measurement, m) of response.info.settings.measurements" :key="m">
                                                                <li v-if="measurement.enabled">
                                                                    <label class="capitalize">{{ Helper.capitalizeFirstLetter(measurement.title.replaceAll('_', ' ').replaceAll('rem', 'REM')) }}</label>
                                                                    <div class="field_area has_suffix">
                                                                        <input type="number" placeholder="ex: 37">
                                                                        <span class="suffix" v-if="response.info.unit">{{ response.info.unit }}</span>
                                                                    </div>
                                                                </li>
                                                            </template>
                                                        </ul>
                                                        <ul class="check_list" v-else-if="response.info.is_vitamins || response.info.is_minerals || response.info.is_supplements">
                                                            <template v-for="(measurement, m) of response.info.settings" :key="m">
                                                                <li v-if="measurement.enabled">
                                                                    <label :for="measurement.title.replaceAll(' ', '-').toLowerCase()" class="checkbox">
                                                                        <span></span>
                                                                        <p>{{ measurement.title }}</p>
                                                                    </label>
                                                                </li>
                                                            </template>
                                                        </ul>
                                                        <div class="field_item"  v-else>
                                                            <div class="field_area has_suffix">
                                                                <input type="number" placeholder="ex: 37">
                                                                <span class="suffix" v-if="response.info.unit">{{ response.info.unit }}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ol>
                                            <div class="submit_details">
                                                <span class="missed">Missed</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </template>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'
    import { defineAsyncComponent } from 'vue'
    import SwiperCore, { Navigation, Pagination } from 'swiper'
    import { Swiper, SwiperSlide } from 'swiper/vue'

    import 'swiper/swiper-bundle.min.css'
    SwiperCore.use([Navigation, Pagination])

    import moment from 'moment-timezone'

    const ProgressChart = defineAsyncComponent(() => import('@/pages/progress-tracking/components/ProgressChart'))

    import Helper from '@/utils/Helper'

    export default {
        name: 'progress-report',

        data () {
            return {
                params: {
                    filter: 'all',
                },
                filters:[
                    {
                        type: '7 days',
                        title: 'Last 7 Days'
                    },
                    {
                        type: '14 days',
                        title: 'Last 14 Days'
                    },
                    {
                        type: '30 days',
                        title: 'Last 30 Days'
                    },
                    {
                        type: '90 days',
                        title: 'Last 90 Days'
                    },
                    {
                        type: 'year',
                        title: 'This Year'
                    },
                    {
                        type: 'all',
                        title: 'All Time'
                    },
                ],
                selectedFilter: 'All Time',
                filterDropdown: false,
                progressReport: [],
                currentProgress: [],
                progressTracking: {},
                completed: [],
                missed: [],
                moment,
                isMounted: false,
                answers: [],
                durationFilter: false,
                scenarioFilter: false,
                progressFilter: false,
                contact: {},
                trackingGoals: [],
                Helper,
                chartColor: '#2F7EED',
                editTracking: false,
                settings: {},
                reportLoader: true,
                previewImagePanel: false,
                availableImages: '',
                selectdImageIndex: 0,
                timezone: '',
            };
        },

        props: {
            tracking: Object,
            contactRelation: Object,
        },

        watch: {
            'params.filter' (filter) {
                const vm = this;

                if (vm.isMounted) {
                    const params = {
                                        contact_id: vm.contactRelation.contact_id ? vm.contactRelation.contact_id : vm.contactRelation.id,
                                        assign_id: vm.contactRelation.deleted_at ? vm.contactRelation.id : '',
                                        progress_tracking_id: vm.tracking.id,
                                        filter,
                                    };

                    vm.getContactCurrentProgress(params);
                    vm.getCurrentProgressProof(params);
                }
            },

            contactCurrentProgress: {
                handler (progress) {
                    const vm = this;

                    vm.progressReport   = progress;
                    vm.currentProgress  = vm.progressReport.report;
                    vm.progressTracking = vm.progressReport.progress;
                    vm.completed        = vm.progressReport.completed;
                    vm.missed           = vm.progressReport.missed;
                    vm.contact          = vm.progressReport.contact;
                    vm.trackingGoals    = vm.progressReport.goals;
                    vm.settings         = vm.progressReport.settings;
                    vm.timezone         = vm.progressTracking.recurring_settings.time_zone;

                    if (vm.settings.chart_settings && vm.settings.chart_settings.graph_accent_color) {
                        vm.chartColor = vm.settings.chart_settings.graph_accent_color;
                    }
                },
                deep: true,
            }
        },

        computed: mapState({
            user: state => state.authModule.user,
            submittedProofs: state => state.progressTrackingModule.submittedProofs,
            contactCurrentProgress: state => state.progressTrackingModule.contactCurrentProgress,
            contactCurrentProgressLoader: state => state.progressTrackingModule.contactCurrentProgressLoader,
            contactCurrentProgressLoaderV2: state => state.progressTrackingModule.contactCurrentProgressLoaderV2,
        }),

        components: {
            ProgressChart,
            Swiper,
            SwiperSlide,
        },

        mounted () {
            const vm = this;

            vm.reportLoader = true;

            setTimeout(() => {
                vm.isMounted = true;

                vm.progressReport   = vm.contactCurrentProgress;
                vm.currentProgress  = vm.progressReport.report;
                vm.progressTracking = vm.progressReport.progress;
                vm.completed        = vm.progressReport.completed;
                vm.missed           = vm.progressReport.missed;
                vm.contact          = vm.progressReport.contact;
                vm.trackingGoals    = vm.progressReport.goals;
                vm.settings         = vm.progressReport.settings;
                vm.timezone         = vm.progressTracking.recurring_settings.time_zone;

                if (vm.settings.chart_settings && vm.settings.chart_settings.graph_accent_color) {
                    vm.chartColor = vm.settings.chart_settings.graph_accent_color;
                }

                vm.reportLoader = false;
            }, 1000)

            moment.tz.setDefault(vm.user.timezone);
        },

        methods: {
            ...mapActions({
                getContactCurrentProgress: 'progressTrackingModule/getContactCurrentProgressV2',
                getCurrentProgressProof: 'progressTrackingModule/getCurrentProgressProof',
            }),

            toggleFunc (ev) {
                let el = ev.currentTarget.closest('.content_card_wpr');
                const subEl = el.querySelectorAll('.list li');

                if (el.classList.contains('show')) {
                    el.classList.remove('show');

                    for (let i = 0; i < subEl.length; i++) {
                        subEl[i].classList.remove('show');
                    }
                } else {
                    el.classList.add('show');
                }
            },

            showQuestions (ev) {
                const el = ev.currentTarget.closest('li');

                if (el.classList.contains('show')) {
                    el.classList.remove('show');
                } else {
                    el.classList.add('show');
                }
            },

            closeDurationDropdown () {
                const vm = this;

                vm.durationFilter = false;
            },

            closeScenarioDropdown () {
                const vm = this;

                vm.scenarioFilter = false;
            },

            previewImage (image) {
                const vm = this;

                vm.previewImagePanel  = true;
                vm.availableImages    = image;
            },

            toggleNote(id) {
                const element = document.getElementById(id);
                const classList = Array.from(element.classList);

                document.getElementsByClassName('notes').forEach((el) => {
                    el.classList.remove('show');
                });

                setTimeout(() => {
                    if (classList.includes('show')) {
                        element.classList.remove('show');
                    } else {
                        element.classList.add('show');
                    }
                }, 50);
            },

            closeNotes() {
                document.getElementsByClassName('notes').forEach((el) => {
                    el.classList.remove('show');
                });
            }
        }
    }
</script>

<style scoped>
.stat_container {
    max-width: 1000px;
    /* padding: 20px 20px 100px 20px; */
    margin: 0 auto;
}

.section_header h2 {
    margin-right: auto;
    font-size: 22px;
    line-height: 28px;
    display: flex;
}

.section_header h2 .tag {
    background: #e9e9e9;
    padding: 5px 15px;
    border-radius: 15px;
    font-size: 13px;
    line-height: 18px;
    font-weight: 500;
    color: #121525;
    margin-left: 10px;
}

.section_header .optionDrops {
    font-size: 13px;
    line-height: 18px;
    font-weight: 400;
    color: #5a5a5a;
    text-align: left;
    padding: 7px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    cursor: pointer;
}

.section_header .optionDrops i {
    margin-left: 10px;
    color: #7a7a7a;
    pointer-events: none;
}

.section_header .optionDrops .dropdown_wpr {
    min-width: 160px;
    left: auto;
    right: 1px;
    overflow: hidden;
}

.section_header .optionDrops ul li {
    flex: 1 1 auto;
    border: 0;
    background: transparent;
}

.report_card {
    border-radius: 5px;
    margin-bottom: 40px;
    background: #fff;
}

.report_card .header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 20px;
    border-bottom: 1px solid #e9e9e9;
}

.report_card .user_info {
    display: flex;
    text-align: left;
    align-items: center;
}

.report_card .user_info img {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    margin-right: 15px;
    border: 1px solid #ccc;
}

.report_card .header h4 {
    font-size: 15px;
    line-height: 22px;
    font-weight: 500;
    color: #121525;
    margin-bottom: 3px;
}

.report_card .header h5 {
    font-size: 11px;
    line-height: 15px;
    font-weight: 400;
    color: #5a5a5a;
    margin-bottom: 0;
}

.report_card .total_progress {
    display: flex;
    flex-wrap: wrap;
}

.report_card .total_progress li {
    margin-left: 20px;
    display: flex;
    align-items: flex-end;
}

.report_card .total_progress label {
    padding: 2px 8px;
    display: block;
    border-radius: 12px;
    font-size: 11px;
    line-height: 14px;
    font-weight: 400;
    margin: 0 0 0 5px;
}

.report_card .total_progress label.completed {
    background: #e8fdea;
    color: #30C13E;
}

.report_card .total_progress label.missed {
    background: #ffefef;
    color: #fb1414;
}

.report_card .total_progress h3 {
    font-size: 20px;
    line-height: 25px;
    font-weight: 300;
    color: #121525;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 0;
}

.report_card .total_progress h3 span {
    font-size: 11px;
    line-height: 15px;
    font-weight: 400;
    color: #5a5a5a;
    margin: auto 0 2px 5px;
}

.report_card .overview {
    background: #fff;
    border-radius: 5px;
}

.overview ul {
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
}

.overview ul li {
    padding: 15px;
    width: 33.333%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0;
    cursor: pointer;
}

.overview li h4 {
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
    color: #121525;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.overview li h4 span {
    font-size: 12px;
    line-height: 15px;
    font-weight: 300;
    color: #666;
    padding-top: 4px;
}

.overview li h4 span a {
    color: #121525;
    font-weight: 400;
}

.overview li img {
    max-width: 50px;
    height: auto;
    padding-right: 15px;
}

.overview li .score {
    font-size: 10px;
    line-height: 12px;
    font-weight: 500;
}

.overview li .score span {
    font-size: 8px;
}

.progress_filter_bar {
    width: 190px;
    min-height: 300px;
}

.progress_filter_bar::-webkit-scrollbar {
    display: none;
}

.progress_filter_bar.show {
    right: 0;
}

.progress_filter_bar h4 {
    font-size: 15px;
    line-height: 22px;
    color: #121525;
    font-weight: 500;
    padding: 0px 20px 5px;
    display: flex;
    align-items: center;
}

.progress_filter_bar h4 .close_btn {
    font-size: 13px;
    color: #999999;
    margin-left: auto;
    cursor: pointer;
}

.progress_filter_bar .scenario_box {
    width: auto;
    border: 1px solid #e9e9e9;
    border-radius: 3px;
    position: relative;
    font-size: 11px;
    line-height: 15px;
    font-weight: 500;
    color: #121525;
    padding: 8px 25px 8px 10px;
    margin: 15px;
    cursor: pointer;
}

.progress_filter_bar .scenario_box i {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1px solid #eeeeee;
    font-size: 13px;
    color: #999;
}

.progress_filter_bar .toggle_btn {
    display: flex;
    justify-content: flex-end;
    padding: 6px 20px 12px;
}

.progress_filter_bar .toggle_btn a {
    padding: 4px 10px;
    background: rgb(245, 245, 245);
    font-size: 10px;
    line-height: 12px;
    color: rgb(18, 21, 37);
    font-weight: 500;
    display: block;
    border-radius: 10px;
    cursor: pointer;
}

.progress_filter_bar .category_wpr {
    max-height: 400px;
    overflow-y: auto;
}

.progress_filter_bar .category_wpr::-webkit-scrollbar {
    width: 4px;
}

.progress_filter_bar .category_wpr::-webkit-scrollbar-thumb {
    background: #dbdbdb;
    border-radius: 2px;
}

.progress_filter_bar .category_wpr .category_grp {
    border-top: 1px solid #f5f5f5;
}

.progress_filter_bar .category_wpr .category_grp h4 {
    padding: 12px 15px;
    font-size: 13px;
    line-height: 18px;
    color: #121525;
    font-weight: 500;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.progress_filter_bar .category_wpr .category_grp h4 i {
    margin-left: auto;
    font-size: 11px;
    color: #999999;
    transition: all 0.3s ease-in-out;
}

.progress_filter_bar .category_wpr .category_grp ul {
    display: flex;
    flex-direction: column;
    padding: 0px 15px;
    max-height: 0px;
    overflow: hidden;
    transition: all 0.5s ease-in-out;
}

.progress_filter_bar .category_wpr .category_grp.active ul {
    max-height: 10000px;
}

.progress_filter_bar .category_wpr ul li {
    padding: 10px 0;
    border-radius: 5px;
}

.progress_filter_bar .category_wpr ul li label.checkbox {
    justify-content: flex-start;
}

.progress_filter_bar .category_wpr ul li label.checkbox h5 {
    font-size: 11px;
    line-height: 15px;
    color: #121525;
    font-weight: 500;
    padding-left: 10px;
}

.progress_filter_bar .category_wpr ul li label.checkbox span i {
    font-size: 10px;
    margin: 0;
}

.progress_filter_bar .category_wpr ul li label.checkbox span.active i {
    transform: scale(1);
    opacity: 1;
}

.content_card_wpr {
    border: 1px solid #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.03);
    background: #fff;
    margin-bottom: 30px;
    overflow: hidden;
}

.content_card_wpr .title {
    font-size: 18px;
    line-height: 25px;
    color: #121525;
    font-weight: 400;
    padding: 15px 30px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e9e9e9;
}

.content_card_wpr .title span {
    font-size: 11px;
    line-height: 18px;
    font-weight: 400;
    color: #777;
    margin-left: auto;
}

.content_card_wpr .title h4 {
    font-size: 16px;
    line-height: 25px;
    color: #121525;
    font-weight: 400;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.content_card_wpr .title h4 i {
    font-size: 14px;
    color: #2f7eed;
}

.content_card_wpr .title h4 label {
    font-size: 11px;
    line-height: 20px;
    border-radius: 12px;
    color: #2f7eed;
    background: #e3eefd;
    font-weight: 500;
    display: inline-block;
    padding: 0 6px;
}

.content_card_wpr .title .rate {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
}

.content_card_wpr .title .rate .score,
.tracking_list li .score {
    font-size: 10px;
    line-height: 12px;
    font-weight: 500;
}

.content_card_wpr .title .rate .score span,
.tracking_list li .score span {
    font-size: 8px;
}

.content_card_wpr .title .rate h6 {
    font-size: 9px;
    line-height: 10px;
    color: #5a5a5a;
    font-weight: 400;
    margin-top: 5px;
    max-width: 100px;
    text-align: center;
    display: none;
}

.content_card_wpr .title .toggle_btn {
    width: 20px;
    height: 20px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: #eee;
    font-size: 10px;
    color: #5a5a5a;
    margin-left: 15px;
    transition: all 0.5s ease-in-out;
}

.content_card_wpr.show .title .toggle_btn {
    transform: rotate(-180deg);
}

.content_card_wpr .section_content {
    padding: 0;
    width: 100%;
    max-height: 55px;
    transition: all 0.5s ease-in-out;
}

.content_card_wpr.show .section_content {
    max-height: 1000vh;
    overflow: overlay;
}

.content_card_wpr.show .section_content::-webkit-scrollbar {
    width: 3px;
}

.content_card_wpr.show .section_content::-webkit-scrollbar-thumb {
    background: #dbdbdb;
    border-radius: 2px;
}

.content_card_wpr.show .section_content {
    max-height: 1000vh;
    overflow: overlay;
}

.section_content .list>li {
    display: flex;
    padding: 0 30px;
    min-height: 55px;
    /* border-bottom: 1px solid #f5f5f5; */
    display: flex;
    align-items: center;
    font-size: 13px;
    line-height: 18px;
    font-weight: 400;
    color: #5a5a5a;
}
.section_content .list>li.show{
    max-height: max-content;
    overflow-y: auto;
}
.section_content .list>li.show::-webkit-scrollbar {
    width: 4px;
}

.section_content .list>li.show::-webkit-scrollbar-thumb {
    background-color: #b8b8b8;
    border-radius: 2px;
}
.workout_card li .info_wpr {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    padding-bottom: 15px;
    gap: 0;
    transition: all 0.3s ease-in-out;
}

.workout_card li.show .info_wpr {
    gap: 15px;
}

.workout_card li.show .info_wpr h4 i {
    transform: rotate(-180deg);
}

.workout_card li a {
    font-size: 11px;
    line-height: 14px;
    font-weight: 500;
    color: #2f7eed;
    margin: 0 0 0 auto;
    cursor: pointer;
}

.workout_card li.show .info_wpr .details {
    max-height: max-content;
}

/* .workout_card li.show .info_wpr .details.has-scroll {
    overflow-y: auto;
}

.workout_card li.show .info_wpr .details.has-scroll::-webkit-scrollbar {
    width: 4px;
}

.workout_card li.show .info_wpr .details.has-scroll::-webkit-scrollbar-thumb {
    background-color: #b8b8b8;
    border-radius: 2px;
} */

.workout_card li:last-child .info_wpr {
    border-bottom: 0;
}

.workout_card li .card_wrapper {
    border-radius: 6px;
    display: flex;
    gap: 15px;
    cursor: pointer;
    padding: 15px 0 0 0;
    width: 100%;
    pointer-events: none;
}
.content_card_wpr.show .workout_card li .card_wrapper {
    pointer-events: all;
}

.workout_card li .card_wrapper svg {
    flex: 0 0 20px;
    height: 20px;
}

.workout_card li .info_wpr h4 {
    font-size: 13px;
    line-height: 18px;
    color: #5a5a5a;
    font-weight: 400;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.workout_card li .info_wpr h4 .date {
    font-weight: 500;
    color: #121525;
    padding-right: 10px;
}

.workout_card li .info_wpr span.tag {
    background: #ecf4ff;
    padding: 0 6px;
    border-radius: 8px;
    font-size: 9px;
    line-height: 15px;
    color: #2f7eed;
    margin-left: 6px;
}

.workout_card li .info_wpr h4 i {
    font-size: 10px;
    margin-left: auto;
    color: #999;
    transition: all 0.3s ease-in-out;
    display: none;
}

.content_card_wpr.show .workout_card li .info_wpr h4 i {
    display: block;
}

.workout_card li .info_wpr h6 {
    font-size: 11px;
    line-height: 15px;
    color: #5a5a5a;
    font-weight: 500;
    display: flex;
    flex-wrap: wrap;
    /* margin: 0 -5px 5px -5px; */
    gap: 5px;
}

.workout_card li .info_wpr h6 span {
    padding: 0 5px 0 0;
}

.workout_card li .info_wpr h6 span:not(:last-child) {
    border-right: 1px solid #d9d9d9;
}

.workout_card li .info_wpr .blocks {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding-bottom: 10px;
    flex: 1 1 auto;
}

.workout_card li .info_wpr .field_list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    max-width: 600px;
    width: 100%;
    text-align: left;
}

.workout_card li .info_wpr .field_list li,
.workout_card li .info_wpr .todo_list li .field_list li {
    width: 25%;
    padding: 10px;
    display: block;
}

.workout_card li .info_wpr .field_list label,
.workout_card li .info_wpr .todo_list li .field_list label {
    display: block;
    font-size: 13px;
    line-height: 16px;
    color: #5a5a5a;
    font-weight: 400;
    padding-bottom: 5px;
    width: auto;
    height: auto;
    border-radius: 0;
    border: 0;
}

.workout_card li .info_wpr .field_area {
    display: flex;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    position: relative;
}

.workout_card li .info_wpr .field_area input {
    height: 40px;
    width: 100%;
    padding: 0 5px 0 10px;
    font-size: 11px;
    color: #5a5a5a;
    background: transparent;
}

.workout_card li .info_wpr .field_area .suffix {
    background: #f5f5f5;
    border-left: 1px solid #e9e9e9;
    border-radius: 0 5px 5px 0;
    height: 40px;
    min-width: 40px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    line-height: 14px;
    color: #5a5a5a;
    font-weight: 500;
}

.workout_card li .info_wpr .field_item {
    margin: 10px 0;
    max-width: 280px;
    width: 100%;
    text-align: left;
}

.workout_card li .info_wpr .submitted_data {
    padding-top: 5px;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    color: #121525;
}

.workout_card li .info_wpr .submitted_data span {
    font-size: 13px;
    line-height: 18px;
    font-weight: 400;
    color: #5a5a5a;
    padding-left: 5px;
}

.workout_card li .info_wpr .todo_list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 10px;
}

.workout_card li .info_wpr .todo_list li {
    font-size: 13px;
    line-height: 20px;
    font-weight: 400;
    color: #5a5a5a;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;
}

.workout_card li .info_wpr .todo_list li label {
    /* width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 1px solid #d9d9d9; */
    color: #777;
    font-size: 13px;
    line-height: 16px;
    font-weight: 400;
    display: flex;
    justify-content: center;
    flex-shrink: 0;
    margin-top: 2px;
}

.workout_card li .info_wpr .todo_list li .task_ans {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
}

.workout_card li .info_wpr .todo_list li .task_ans .yes_no_box {
    display: flex;
    gap: 7px;
}

.workout_card li .info_wpr .todo_list li .task_ans .yes_no_box.disabled {
    opacity: 0.5;
}

.workout_card li .info_wpr .todo_list li .task_ans .yes_box {
    width: 60px;
    border: 1px solid #b2eabf;
    background: #effff2;
    color: #23993e;
    font-size: 11px;
    line-height: 15px;
    font-weight: 500;
    border-radius: 4px;
    padding: 5px 10px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 7px;
}

.workout_card li .info_wpr .todo_list li .task_ans .yes_box i {
    font-size: 10px;
    margin-top: 1px;
}

.workout_card li .info_wpr .todo_list li .task_ans .no_box {
    width: 60px;
    border: 1px solid #ffb4b4;
    background: #ffecec;
    color: #eb1414;
    font-size: 11px;
    line-height: 15px;
    font-weight: 500;
    border-radius: 4px;
    padding: 5px 10px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 7px;
}

.workout_card li .info_wpr .todo_list li .task_ans .no_box i {
    font-size: 10px;
    margin-top: 3px;
}

.workout_card li .info_wpr .todo_list li .task_ans .ans_text {
    min-width: 50px;
    background: #f5f5f5;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 11px;
    line-height: 15px;
    font-weight: 500;
    color: #5a5a5a;
}


.workout_card li .info_wpr .details {
    display: flex;
    flex-wrap: wrap;
    max-height: 0;
    overflow: hidden;
    gap: 15px;
    transition: all 0.3s ease-in-out;
}

.workout_card li .info_wpr .details .redirect_btn {
    font-size: 11px;
    line-height: 14px;
    padding: 6px 12px;
    border-radius: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    cursor: pointer;
    margin: 0 auto 0 0;
    text-decoration: none;
}

.workout_card li .info_wpr .details .redirect_btn.missed {
    color: #eb1414;
    margin: 0 0 0 auto;
}

.workout_card li .info_wpr .details .submit_details {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.workout_card li .info_wpr .details .submit_details label {
    font-size: 11px;
    line-height: 14px;
    font-weight: 400;
    color: #5a5a5a;
}

.workout_card li .info_wpr .details .submit_details span {
    font-size: 11px;
    line-height: 14px;
    font-weight: 400;
    color: #2f7eed;
    background: #f0f6ff;
    padding: 4px 10px;
    border-radius: 12px;
}

.workout_card li .info_wpr .details .submit_details span.delay {
    color: #f2a31d;
    background: #fff6e6;
}

.workout_card li .info_wpr .details .submit_details span.success {
    color: #23993e;
    background: #effff2;
}

.workout_card li .info_wpr .details .submit_details span.missed {
    color: #eb1414;
    background: #fff2f2;
}

.dropdown_wpr ul li {
    padding: 8px 14px;
}

.category_label {
    font-size: 20px;
    line-height: 25px;
    font-weight: 500;
    color: #121525;
    padding: 15px 0;
    display: flex;
    justify-content: center;
    position: relative;
}

.category_label:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 25%;
    right: 25%;
    border-bottom: 1px solid #d9d9d9;
    z-index: 0;
}

.category_label span {
    padding: 7px 15px;
    background: #fafafb;
    position: relative;
    z-index: 1;
}

.category_label {
    font-size: 13px;
    line-height: 16px;
}

.category_label:before {
    left: 15%;
    right: 15%;
}

ul.scroll {
   height: 200px;
   overflow-y: scroll;
   scroll-behavior: smooth;
}

ul.scroll::-webkit-scrollbar {
    width: 4px;
}

ul.scroll::-webkit-scrollbar-thumb {
    background-color: #b8b8b8;
    border-radius: 2px;
}

.workout_card li .info_wpr .field_list li.rest-field {
    width: 50%;
}
.workout_card li .info_wpr .field_list li.rest-field div.rest {
    display: flex;
    gap: 15px;
}

.workout_card li .info_wpr .check_list {
    display: flex;
    flex-wrap: wrap;
    margin: 10px -5px;
    text-align: left;
}

.workout_card li .info_wpr .check_list li {
    width: 150px;
    padding: 5px;
}

.workout_card li .info_wpr label.checkbox {
    justify-content: flex-start;
}

label.checkbox span {
    width: 16px;
    flex: 0 0 16px;
    height: 16px;
    border: 1px solid #BABDC3;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    cursor: pointer;
    padding: 0 !important;
}

.workout_card li .info_wpr .checkbox p {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: #5a5a5a;
    padding-left: 10px;
    width: 100%;
}

.workout_card li .info_wpr label.checkbox span i {
    transform: scale(1);
    opacity: 1;
}

.workout_card li .info_wpr .todo_list .check_list li label {
    border: 0px solid transparent;
    width: 100%;
}

.quote-loader-wrapper {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pic-label {
    background-color: var(--var-element-light-color, #0d6efd) !important;
    /* color: var(--var-element-text-color, #FFF) !important; */
}
.pic_wrapper{
    margin: 20px 0 40px 0;
}
.pic_wrapper .title{
    font-size: 18px;
    line-height: 25px;
    color: #121525;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0 0 15px 0;
}
.title img{
    max-height: 30px;
    width: auto;
    padding-right: 10px;
}
.pic_wrapper .pic_slider{
    padding: 0 20px;
    margin: 0 -20px;
}
.pic_wrapper .pic_slider :deep(.swiper-button-next){
    width: 20px;
    right: 0;
}
.pic_wrapper .pic_slider :deep(.swiper-button-prev){
    width: 20px;
    left: 0;
}
.pic_wrapper .pic_slider :deep(.swiper-button-next:after),
.pic_wrapper .pic_slider :deep(.swiper-button-prev:after) {
    font-size: 18px;
    font-weight: 600;
}
.pic_wrapper .picture_section{
    /* background: #fff; */
    /* border-radius: 8px; */
    overflow: hidden;
}
.pic_wrapper .picture_section .date {
    /* font-size: 15px;
    line-height: 20px;
    color: #121525;
    font-weight: 400; */
    font-size: 13px;
    line-height: 16px;
    font-weight: 500;
    text-align: right;
    color: #5a5a5a;
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
}
.pic_wrapper .picture_section .date .caption{
    opacity: 0;
    transition: all 0.3s ease-in-out;
}
.pic_wrapper .picture_section:hover .date .caption{
    opacity: 1;
}
.picture_section .section_wpr{
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    border-radius: 8px;
    overflow: hidden;
}
.picture_section .section_wpr li {
    width: 33.333%;
}

.picture_section .section_wpr li:not(:last-child)  .section_title{
    border-right: 1px solid #eaeaea;
}

.picture_section .section_wpr li {
    flex: 1;
    line-height: 0;
}

.picture_section .section_wpr li .section_title {
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e9e9e9;
    background: #fff;
}

.picture_section .section_wpr li .section_title h4 {
    font-size: 11px;
    line-height: 14px;
    font-weight: 500;
    text-align: left;
    color: #5a5a5a;
}

.picture_section .section_wpr li .section_title h4 span {
    font-size: 10px;
    line-height: 12px;
    font-weight: 500;
    display: block;
    padding-top: 3px;
    color: #757575;
}

.picture_section .section_wpr li .section_title i {
    font-size: 12px;
    width: 25px;
    height: 25px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
    border: 1px solid #e9e9e9;
    border-radius: 50%;
    margin-left: 5px;
    color: #2f7eed;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.picture_section .section_wpr li img {
    width: 100%;
}
.picture_section .section_wpr li .img_viewer {
    position: relative;
    overflow: hidden;
}
.picture_section .section_wpr li .img_viewer {
    border-right: 1px solid #fff;
}
.img_viewer .lightbox_btn {
    width: 20px;
    height: 20px;
    background: #fff;
    border-radius: 50%;
    font-size: 11px;
    color: #5a5a5a;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: -20px;
    top: 10px;
    z-index: 1;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.img_viewer .download_btn {
    width: 20px;
    height: 20px;
    background: #fff;
    border-radius: 50%;
    font-size: 11px;
    color: #5a5a5a;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: -20px;
    top: 10px;
    z-index: 1;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}
.img_viewer:hover .lightbox_btn {
    left: 10px;
}

.img_viewer:hover .download_btn {
    right: 10px;
}

.note-wpr {
    position: relative;
}

.overview .notes {
    background: #fff;
    border-radius: 5px;
    border: 0;
    position: absolute;
    right: 20px;
    top: 100%;
    z-index: -2;
    max-height: 0;
    overflow: hidden;
    animation: smoothSlidedown 0.3s;
    animation-timing-function: cubic-bezier(1.0, 0.5);
    min-width: 250px;
}

.overview .notes label {
    font-weight: 500;
    margin-bottom: 7px;
    border-bottom: 1px solid #f5f5f5;
    font-size: 13px;
    width: 100%;
    display: block;
    padding: 15px;
    padding-bottom: 7px;
}

.overview .notes ul {
    padding: 2px 25px;
    list-style-type: disc;
}

.overview .notes ul li {
    font-weight: 400;
    font-size: 11px;
    width: 100%;
    padding: 0;
    list-style: disc;
    list-style-type: disc;
    display: list-item;
}

.overview .notes.show {
    z-index: 2;
    border: 1px solid #f5f5f5;
    box-shadow: 0 1px 20px rgb(0,0,0,0.15);
    max-height: 500px;
    animation: smoothSlideup 0.8s;
    animation-timing-function: cubic-bezier(1.0, 0.5);
}

@media(max-width: 767px){
    .section_header{
        row-gap: 0;
    }
}
@media(max-width: 767px){
    .report_card .header{
        padding: 20px;
    }
    .overview ul li{
        width: 50%;
    }
    .section_header{
        flex-wrap: wrap;
    }
    .section_header h2{
        width: 100%;
        margin-bottom: 15px;
    }
    .section_header .optionDrops{
        padding-left: 0;
        padding-right: 0;
    }
    .section_header .optionDrops.contacts-tabs .dropdown_wpr{
        left: 1px;
        right: auto;
    }
}
@media(max-width: 599px){
    .report_card .header{
        flex-wrap: wrap;
    }
    .overview ul li{
        width: 100%;
    }
    .report_card .total_progress{
        flex-direction: column;
        align-items: flex-end;
    }
    .report_card .total_progress li{
        padding: 2px 0;
    }
    .section_header h2{
        flex-direction: column;
        align-items: flex-start;
    }
    .section_header h2 .tag{
        padding: 4px 12px;
        font-size: 11px;
        line-height: 15px;
        margin: 10px 0 0 0;
    }
    .workout_card li .info_wpr .todo_list li .task_ans .field_list li{
        width: 50%;
    }
}
@media(max-width: 499px){
    .report_card .user_info,
    .report_card .total_progress{
        width: 100%;
    }
    .report_card .total_progress{
        margin-top: 10px;
        flex-direction: row;
    }
    .report_card .total_progress li{
        margin-left: 0;
        margin-right: 20px;
    }
    .report_card .header h3 span{
        display: block;
        font-size: 11px;
        line-height: 15px;
    }
    .stat_container .score_circle {
        transform: scale(1);
    }
    .content_card_wpr .title{
        padding: 15px;
    }
    .section_content .list > li{
        padding: 0 15px;
    }
    .workout_card li .card_wrapper{
        gap: 12px;
    }
    .workout_card li .info_wpr .details .submit_details{
        flex-direction: column;
        align-items: flex-start;
    }
    .workout_card li .info_wpr .details .submit_details span{
        margin-bottom: 10px;
    }
    .workout_card li .info_wpr .details .submit_details .redirect_btn{
        padding: 6px 0;
        margin-bottom: 10px;
    }
    .workout_card li .info_wpr .details .submit_details .redirect_btn.missed{
        padding: 6px 0;
        margin: 0 0 10px 0;
    }
}
</style>